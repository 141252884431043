(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name myOrder.controller:MyOrderCtrl
   *
   * @description
   *
   */
  angular
    .module('myOrder')
    .controller('MyOrderCtrl', MyOrderCtrl);

  function MyOrderCtrl($scope, $rootScope, consts, $sce, $mdDialog, $timeout, MyOrder, App) {
    $rootScope.pageTitle = "ההזמנה שלי";
    $scope.currentOrder = MyOrder.getOrder();

    $scope.minDate = new Date();
    $scope.maxDate = undefined;

    $scope.paymentData = $scope.getItemFromLocalStorage("paymentData");


    $scope.user = $scope.getItemFromLocalStorage("userDetails");

    if ($scope.user == undefined) $scope.user = {};

    $scope.updateUserDetails = function(valid) {
      if (!valid) return;
      $scope.orderStep++;

      if ($scope.user.Address != undefined && typeof($scope.user.Address) != "string") {
        var place = getDataFromGooglePlaceObject($scope.user.Address);
        $scope.user.Longitude = place.Longitude;
        $scope.user.Latitude = place.Latitude;
        $scope.user.Address = place.Address;
      }
      $scope.setItemInLocalStorage("userDetails", $scope.user);
      App.updateUserDetails($scope.user);
    }

    $scope.payAndOrder = function() {
      $scope.orderStep = 1;
      //$scope.currentOrder.time = new Date();

      $scope.currentOrder.orderType = 'delivery';

      if ($scope.currentOrder.orderTypeTime == undefined) {
        $scope.currentOrder.orderTypeTime = 'now';
      }
      var loginToken = $scope.getItemFromLocalStorage("loginToken");
      //$scope.currentOrder.totalPrice
      $scope.tranzilaIframe = $sce.trustAsResourceUrl("https://direct.tranzila.com/" + consts.tranzilaUsername + "/iframe.php" + "?sum=" + $scope.currentOrder.totalPrice + "&currency=1&cred_type=1&tranmode=AK&pdesc=" + consts.franchiseId + "&franchiseId=" + consts.franchiseId + "&userLoginToken=" + loginToken);

      $mdDialog.show({
        templateUrl: 'order-steps/steps.html',
        scope: $scope,
        preserveScope: true
      });
    }

    window.iframeLoad = function(iframe) {
      console.log("iframeLoad");
      console.log($("#tranzilaIframe")[0].contentWindow.location.search);

      if ($("#tranzilaIframe")[0].contentWindow.location.search.indexOf("TranzilaTK") != -1) {
        console.log("GOOD");

        var params = $("#tranzilaIframe")[0].contentWindow.location.search.split("&");


        var tranzilaToken = _.find(params, function(e) {
          return e.indexOf("TranzilaTK") != -1
        });
        tranzilaToken = tranzilaToken.split("TranzilaTK=");
        tranzilaToken = tranzilaToken[tranzilaToken.length - 1];

        var expyear = _.find(params, function(e) {
          return e.indexOf("expyear") != -1
        });
        expyear = expyear.split("expyear=");
        expyear = expyear[expyear.length - 1];
        if (expyear.length == 1) {
          expyear = "0" + expyear;
        }

        var expmonth = _.find(params, function(e) {
          return e.indexOf("expmonth") != -1
        });
        expmonth = expmonth.split("expmonth=");
        expmonth = expmonth[expmonth.length - 1];
        if (expmonth.length == 1) {
          expmonth = "0" + expmonth;
        }

        var paymentData = {
          tranzilaToken: tranzilaToken,
          expdate: expmonth + expyear
        }

        var loginToken = $scope.getItemFromLocalStorage("loginToken");
        $scope.setItemInLocalStorage("paymentData", paymentData);

        MyOrder.paymentRequest($scope.currentOrder, loginToken, tranzilaToken, expmonth + expyear, $scope.user).then(function(response) {
          $scope.paymentCallBack(response);
        });
      }
    }

    $scope.showTimePicker = function(ev) {
      // $scope.currentOrder.orderTypeTime = "future";
      document.getElementById('takeawayTimepicker').dispatchEvent(new Event('focus'));
    }


    $scope.branchChanged = function(branch) {
      if (!branch.IsOpen) {
        //    $scope.currentOrder.orderType = 'takeaway';
        $scope.currentOrder.orderTypeTime = "future";
        $scope.currentOrder.time = getDateTimeFromTimeStr($scope.currentOrder.branch.OpeningTime);
        $scope.minDate = getDateTimeFromTimeStr($scope.currentOrder.branch.OpeningTime);
        $scope.maxDate = getDateTimeFromTimeStr($scope.currentOrder.branch.ClosingTime);

      } else {
        $scope.minDate = new Date();
        $scope.currentOrder.time = new Date();
        $scope.maxDate = getDateTimeFromTimeStr($scope.currentOrder.branch.ClosingTime);
      }
      var timeToAdd = $scope.currentOrder.orderType == 'delivery' ? $scope.currentOrder.branch.DeliveryTimeInMinutes : $scope.currentOrder.branch.TakeawayTimeInMinutes;
      $scope.minDate.setMinutes($scope.minDate.getMinutes() + timeToAdd);
      $scope.currentOrder.time.setMinutes($scope.currentOrder.time.getMinutes() + timeToAdd);
    }

    $scope.setOrderType = function(type) {
      $scope.currentOrder.orderType = type;

      //Order type changed - min time to delivery changed (takeaway or delivery)
      if (!$scope.currentOrder.branch.IsOpen) {
        $scope.minDate = getDateTimeFromTimeStr($scope.currentOrder.branch.OpeningTime);
        $scope.currentOrder.time = getDateTimeFromTimeStr($scope.currentOrder.branch.OpeningTime);
      } else {
        $scope.minDate = new Date();
        $scope.currentOrder.time = new Date();
      }

      var timeToAdd = $scope.currentOrder.orderType == 'delivery' ? $scope.currentOrder.branch.DeliveryTimeInMinutes : $scope.currentOrder.branch.TakeawayTimeInMinutes;
      $scope.currentOrder.time.setMinutes($scope.currentOrder.time.getMinutes() + timeToAdd);
      $scope.minDate.setMinutes($scope.minDate.getMinutes() + timeToAdd);

    }

    var animationTimeout = undefined;

    $scope.nextOrderStep = function(clickedOrderStep) {
      if (clickedOrderStep == undefined) {
        $scope.orderStep++;
      } else {
        if (clickedOrderStep < $scope.orderStep) $scope.orderStep = clickedOrderStep; //Cant skip steps
      }
      //If 'credit' goes to iframe and the n pay.
      if ($scope.orderStep == 4 && $scope.currentOrder.payment != 'credit') {
        var loginToken = $scope.getItemFromLocalStorage("loginToken");

        if ($scope.paymentData == undefined) $scope.paymentData = {};

        MyOrder.paymentRequest($scope.currentOrder, loginToken, $scope.paymentData.tranzilaToken, $scope.paymentData.expdate, $scope.user).then(function(response) {
          $scope.paymentCallBack(response);
        });
      }
    }

    $scope.paymentCallBack = function(response) {
      var now = new Date()
      $scope.currentOrder.ordered = true;
      $rootScope.$broadcast("itemAddedToOrder",0)
      $scope.currentOrder.orderedDate = now.toLocaleDateString("pt");
      $scope.currentOrder.orderedTime = now.toLocaleTimeString("pt");
      $scope.currentOrder.payment = 'creditSuccess';

      if ($scope.currentOrder.branch.IsOpen) {
        $scope.currentOrder.estimatedTime = $scope.currentOrder.orderType == 'delivery' ? $scope.currentOrder.branch.DeliveryTimeInMinutes : $scope.currentOrder.branch.TakeawayTimeInMinutes;
      } else {
        $scope.currentOrder.estimatedTime = $scope.currentOrder.time.toLocaleTimeString("pt");
      }
      animationTimeout = $timeout(function() {
        $scope.slideUp();
        $scope.hideDialog();
      }, 5000);
    }


    $scope.removeItemFromOrder = function(item, index) {
      MyOrder.removeItemFromOrder(item, index);
    }

    $scope.addOne = function(item, index) {
      MyOrder.addItemAmount(index);
    }
    $scope.removeOne = function(item, index) {
      if (item.amount == 1) return;
      MyOrder.subItemAmount(index);
    }

    var getDateTimeFromTimeStr = function(time) {
      var dateTime = new Date();
      if (time != undefined && time.indexOf(":") >= 0) {
        dateTime = new Date(dateTime.setMinutes(time.split(":")[1]));
        dateTime = new Date(dateTime.setHours(time.split(":")[0]));
        return dateTime;
      }
      return undefined;
    }

    var minutesOfDay = function(date) {
      return date.getMinutes() + date.getHours() * 60;
    }

    var getDataFromGooglePlaceObject = function(place) {
      if (place == undefined || place.geometry == undefined) return {};
      return {
        Longitude: place.geometry.location.lng(),
        Latitude: place.geometry.location.lat(),
        Address: place.formatted_address
      }
    }

    $scope.$on("$destroy", function() {
      if (animationTimeout) {
        $timeout.cancel(animationTimeout);
      }
    });

    if ($scope.branches.length == 1) {
      $scope.currentOrder.branch = $scope.branches[0];
      $scope.branchChanged($scope.currentOrder.branch);
    }

  }
}());
